<template>
  <div>

    <div class="card">
      <div class="card-header d-flex justify-content-between align-items-center">
        <div class="col-lg-3">
          <label for="">Фильтрация по клиенту</label>
          <a-select
              mode="multiple"
              placeholder="Выберите клиента"
              v-model="dataFilter.clients"
              style="width: 100%"
              optionLabelProp="label"
              optionFilterProp="label"
              @change="applyFilter"
            >
            <a-select-option v-for="client in clients" :key="client.id" :value="client.id" :label="client.name">
              {{client.name}}
            </a-select-option>
          </a-select>
        </div>
        <div class="col-sm-3">
          <label for="">Дата и время от </label>
           <a-date-picker style="width: 100%" @change="changeDateFrom" placeholder="Выберите дату и время" show-time format="DD-MM-YYYY HH:mm:ss"></a-date-picker>
        </div>
        <div class="col-sm-3">
          <label for="">Дата и время до </label>
          <a-date-picker style="width: 100%" @change="changeDateTo" placeholder="Выберите дату и время" show-time format="DD-MM-YYYY HH:mm:ss"></a-date-picker>
        </div>
        <div class="col-lg-3">
          <label for="">Поиск по промокоду</label>
          <template>
            <a-input style="width: 100%" placeholder="Промокод"
            v-model="dataFilter.name"
            @change="applyFilter"
            />
          </template>
        </div>

      </div>
    </div>

    <mini-loader v-if="load" />
    <div class="card" style="overflow-x: auto; max-width: 100%">
      <div class="card-header d-flex justify-content-between align-items-center">
        <h5 class="text-dark">Все адреса</h5>
        <a @click="createItem">
          <span class=" p-1">
            <button type="button" class="btn btn-success" >Добавить
              <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-plus-lg" viewBox="0 0 20 20">
                <path d="M8 0a1 1 0 0 1 1 1v6h6a1 1 0 1 1 0 2H9v6a1 1 0 1 1-2 0V9H1a1 1 0 0 1 0-2h6V1a1 1 0 0 1 1-1z"/>
              </svg>
            </button>

          </span>
        </a>
      </div>
      <table class="table table-striped" id="addressesList">
        <thead>
          <tr>
            <th scope="col">#</th>
            <td>Клиент</td>
            <td>Промокод</td>
            <td>Заголовок</td>
            <td>Скидка (значение)</td>
            <td>Статус</td>
            <td>Количество</td>
            <td>Использовано</td>
            <td>Отсаток</td>
            <th scope="col">Действие</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(value, index) in data">
            <th scope="row">{{index+1}}</th>
            <td> <span v-if="value.client" style="color: #5355D3; cursor: pointer">{{value.client.name}}</span> <span v-else>-</span> </td>
            <td> {{value.code}} </td>
            <td> {{value.title}} </td>
            <td> {{value.value}} </td>
            <td> <span class="btn btn-success" v-if="value.status == 'active'">Активный</span> <span class="btn btn-danger" v-else>Неактивный</span> </td>
            <td> {{value.count}} </td>
            <td> {{value.use ? value.use : 0}} </td>
            <td> {{value.remainder}} </td>
            <td style="display: flex">
              <a  @click="showItem(value)">
                <span class="border rounded border-primary m-1 p-2 text-primary">
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-eye" viewBox="0 0 16 16">
                    <path d="M16 8s-3-5.5-8-5.5S0 8 0 8s3 5.5 8 5.5S16 8 16 8zM1.173 8a13.133 13.133 0 0 1 1.66-2.043C4.12 4.668 5.88 3.5 8 3.5c2.12 0 3.879 1.168 5.168 2.457A13.133 13.133 0 0 1 14.828 8c-.058.087-.122.183-.195.288-.335.48-.83 1.12-1.465 1.755C11.879 11.332 10.119 12.5 8 12.5c-2.12 0-3.879-1.168-5.168-2.457A13.134 13.134 0 0 1 1.172 8z"/>
                    <path d="M8 5.5a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5zM4.5 8a3.5 3.5 0 1 1 7 0 3.5 3.5 0 0 1-7 0z"/>
                  </svg>
                </span>
              </a>
              <a @click="editItem(value)">
                <span class="border rounded border-success m-1 p-2 text-success">
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-pencil" viewBox="0 0 16 16">
                    <path d="M12.146.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1 0 .708l-10 10a.5.5 0 0 1-.168.11l-5 2a.5.5 0 0 1-.65-.65l2-5a.5.5 0 0 1 .11-.168l10-10zM11.207 2.5 13.5 4.793 14.793 3.5 12.5 1.207 11.207 2.5zm1.586 3L10.5 3.207 4 9.707V10h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.293l6.5-6.5zm-9.761 5.175-.106.106-1.528 3.821 3.821-1.528.106-.106A.5.5 0 0 1 5 12.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.468-.325z"/>
                  </svg>
                </span>
              </a>
              <a @click="deleteItem(index, value)">
                <span class="border rounded border-danger m-1 p-2 text-danger">
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-trash" viewBox="0 0 16 16">
                    <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z"/>
                    <path fill-rule="evenodd" d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"/>
                  </svg>
                </span>
              </a>
            </td>
          </tr>
        </tbody>
      </table>
      <p class="m-10 text-dark text-center" style="border: 1px solid #DFE1E4; padding: 10px">1 - {{data.length}} данных  из {{totalResults}}</p>
    </div>

    <!-- Create modal -->
    <a-modal v-model="visibleCreateModal" title="Добавить адреса"  footer="" class="address-create">

      <div class="mb-3">
        <label for="">Клиент <span style="color: #e55353">*</span></label>
        <a-select
            show-search
            placeholder="Клиент"
            style="width: 100%"
            v-model="newItem.client_id"
            optionLabelProp="label"
            optionFilterProp="label"
            :default-value="'residential'"
            :allowClear="true"
            :class="{'is-invalid': validateErrors.client_id}"
            @change="filterTariffs"
          >
          <a-select-option v-for="client in clients" :key="client.id" :value="client.id" :label="client.name">
            {{client.name}}
          </a-select-option>
        </a-select>
        <div class="invalid-feedback" v-for="value in validateErrors.client_id"> {{value}} </div>
      </div>

      <div class="mb-3">
        <label for="">Промокод <span style="color: #e55353">*</span></label>
        <template>
          <a-input placeholder="Промокод" v-model="newItem.code" :class="{'is-invalid': validateErrors.code}"
          />
          <div class="invalid-feedback" v-for="value in validateErrors.code"> {{value}} </div>
        </template>
      </div>
      <div class="mb-3">
        <label for="">Заголовок <span style="color: #e55353">*</span></label>
        <template>
          <a-input placeholder="Заголовок" v-model="newItem.title" :class="{'is-invalid': validateErrors.title}"
          />
          <div class="invalid-feedback" v-for="value in validateErrors.title"> {{value}} </div>
        </template>
      </div>
      <div>
        <label for="">Заметки</label>
        <template>
          <a-textarea v-model="newItem.description" placeholder="Заметки" :class="{'is-invalid': validateErrors.description}"
          />
          <div class="invalid-feedback" v-for="value in validateErrors.description"> {{value}} </div>
        </template>
      </div>

      <hr>
      <div class="d-flex">
        <div class="mb-3 mr-1">
          <label for="">При минимальной цене <span style="color: #e55353">*</span></label>
          <template>
            <a-input placeholder="При минимальной цене" v-model="newItem.min_price" :class="{'is-invalid': validateErrors.min_price}"
            />
            <div class="invalid-feedback" v-for="value in validateErrors.min_price"> {{value}} </div>
          </template>
        </div>
        <div class="mb-3 ml-1">
          <label for="">При максимальной цене <span style="color: #e55353">*</span></label>
          <template>
            <a-input placeholder="При максимальной цене" v-model="newItem.max_price" :class="{'is-invalid': validateErrors.max_price}"
            />
            <div class="invalid-feedback" v-for="value in validateErrors.max_price"> {{value}} </div>
          </template>
        </div>
      </div>
      <hr>

      <div class="d-flex">
        <div class="mb-3 w-50">
          <label for="">Тип скидки <span style="color: #e55353">*</span></label>
          <a-select
              placeholder="Тип скидки"
              style="width: 100%"
              v-model="newItem.type"
              optionLabelProp="label"
              optionFilterProp="label"
              :default-value="'residential'"
              :allowClear="true"
              :class="{'is-invalid': validateErrors.type}"
            >
            <a-select-option v-for="type in types" :key="type.value" :value="type.value" :label="type.title">
              {{type.title}}
            </a-select-option>
          </a-select>
          <div class="invalid-feedback" v-for="value in validateErrors.type"> {{value}} </div>
        </div>
        <div class="mb-3 ml-1 w-50">
          <label for="">Скидка (значение) <span style="color: #e55353">*</span></label>
          <template>
            <a-input placeholder="Скидка (значение)" v-model="newItem.value" :class="{'is-invalid': validateErrors.value}"
            />
            <div class="invalid-feedback" v-for="value in validateErrors.value"> {{value}} </div>
          </template>
        </div>
      </div>
      <hr>
      <div class="d-flex">
        <div class="mb-3 w-50">
          <label for="">Тип услуги <span style="color: #e55353">*</span></label>
          <a-select
              placeholder="Тип услуги"
              style="width: 100%"
              v-model="newItem.type_of_service"
              optionLabelProp="label"
              optionFilterProp="label"
              :default-value="'residential'"
              :allowClear="true"
              :class="{'is-invalid': validateErrors.type_of_service}"
              @change="filterTariffs"
            >
            <a-select-option v-for="type in typeOfServices" :key="type.value" :value="type.value" :label="type.title">
              {{type.title}}
            </a-select-option>
          </a-select>
          <div class="invalid-feedback" v-for="value in validateErrors.type_of_service"> {{value}} </div>
        </div>
        <div class="ml-5">
          <div class="form-group">
            <label for="exampleInputEmail">Статус промокода <span :style="{color: status == 'Активный' ? '#2EB85C' : 'red'}" >({{status}})</span> </label>
            <a-form-item>
              <a-switch default-checked v-decorator="['switch', { valuePropName: 'active' }]" @change="changeSwitchItem" />
            </a-form-item>
            <div class="invalid-feedback" v-for="value in validateErrors.status"> {{value}} </div>
          </div>
        </div>
      </div>

      <div class="d-flex">
        <div class="mb-3 mr-1">
          <label for="">Дата и время от</label>
          <a-date-picker style="width: 100%" @change="changeDateFromInCreate" placeholder="Выберите дату и время" show-time format="DD-MM-YYYY HH:mm:ss"></a-date-picker>
        </div>
        <div class="mb-3 ml-1">
          <label for="">Дата и время до</label>
          <a-date-picker style="width: 100%" @change="changeDateToInCreate" placeholder="Выберите дату и время" show-time format="DD-MM-YYYY HH:mm:ss"></a-date-picker>
        </div>
      </div>

      <div class="d-flex">
        <div class="mb-3 mr-1">
          <label for="">Количество <span style="color: #e55353">*</span></label>
          <template>
            <a-input placeholder="Количество" v-model="newItem.count" :class="{'is-invalid': validateErrors.count}"
            />
            <div class="invalid-feedback" v-for="value in validateErrors.count"> {{value}} </div>
          </template>
        </div>
        <div class="mb-3 ml-1 mr-1">
          <label for="">Использовано </label>
          <template>
            <a-input disabled placeholder="Использовано" v-model="newItem.use" :class="{'is-invalid': validateErrors.use}"
            />
            <div class="invalid-feedback" v-for="value in validateErrors.use"> {{value}} </div>
          </template>
        </div>
        <div class="mb-3 mr-1">
          <label for="">Осталось </label>
          <template>
            <a-input disabled placeholder="Осталось" v-model="newItem.remainder" :class="{'is-invalid': validateErrors.remainder}"
            />
            <div class="invalid-feedback" v-for="value in validateErrors.remainder"> {{value}} </div>
          </template>
        </div>
      </div>
      <hr>
      <div class="">
        <label for="">Тарифы</label>
        <a-select
            mode="multiple"
            placeholder="Выберите тарифа"
            v-model="newItem.tariffs_id"
            style="width: 100%"
            optionLabelProp="label"
            optionFilterProp="label"
           :class="{'is-invalid': validateErrors.tariffs_id}"
          >
          <a-select-option v-for="tariffs in tariffs" :key="tariffs.id" :value="tariffs.id" :label="tariffs.title">
            {{tariffs.title}}
          </a-select-option>
        </a-select>
        <div class="invalid-feedback" v-for="value in validateErrors.tariffs_id"> {{value}} </div>
      </div>
      <hr>
      <div class="d-flex mt-3">
        <button type="submit" class="ml-auto btn btn-primary" @click="storeItem">Добавить</button>
      </div>
    </a-modal>

    <!-- Edit modal -->
    <a-modal v-model="visibleEditModal" title="Изменить адреса"  footer="" class="address-create">
      <div class="mb-3">
        <label for="">Клиент <span style="color: #e55353">*</span></label>
        <a-select
            show-search
            placeholder="Клиент"
            style="width: 100%"
            v-model="item.client_id"
            optionLabelProp="label"
            optionFilterProp="label"
            :default-value="'residential'"
            :allowClear="true"
            :class="{'is-invalid': validateErrors.client_id}"
            @change="filterTariffs"
          >
          <a-select-option v-for="client in clients" :key="client.id" :value="client.id" :label="client.name">
            {{client.name}}
          </a-select-option>
        </a-select>
        <div class="invalid-feedback" v-for="value in validateErrors.client_id"> {{value}} </div>
      </div>

      <div class="mb-3">
        <label for="">Промокод <span style="color: #e55353">*</span></label>
        <template>
          <a-input placeholder="Промокод" v-model="item.code" :class="{'is-invalid': validateErrors.code}"
          />
          <div class="invalid-feedback" v-for="value in validateErrors.code"> {{value}} </div>
        </template>
      </div>
      <div class="mb-3">
        <label for="">Заголовок <span style="color: #e55353">*</span></label>
        <template>
          <a-input placeholder="Заголовок" v-model="item.title" :class="{'is-invalid': validateErrors.title}"
          />
          <div class="invalid-feedback" v-for="value in validateErrors.title"> {{value}} </div>
        </template>
      </div>
      <div>
        <label for="">Заметки</label>
        <template>
          <a-textarea v-model="item.description" placeholder="Заметки" :class="{'is-invalid': validateErrors.description}"
          />
          <div class="invalid-feedback" v-for="value in validateErrors.description"> {{value}} </div>
        </template>
      </div>

      <hr>
      <div class="d-flex">
        <div class="mb-3 mr-1">
          <label for="">При минимальной цене <span style="color: #e55353">*</span></label>
          <template>
            <a-input placeholder="При минимальной цене" v-model="item.min_price" :class="{'is-invalid': validateErrors.min_price}"
            />
            <div class="invalid-feedback" v-for="value in validateErrors.min_price"> {{value}} </div>
          </template>
        </div>
        <div class="mb-3 ml-1">
          <label for="">При максимальной цене <span style="color: #e55353">*</span></label>
          <template>
            <a-input placeholder="При максимальной цене" v-model="item.max_price" :class="{'is-invalid': validateErrors.max_price}"
            />
            <div class="invalid-feedback" v-for="value in validateErrors.max_price"> {{value}} </div>
          </template>
        </div>
      </div>
      <hr>

      <div class="d-flex">
        <div class="mb-3 w-50">
          <label for="">Тип скидки <span style="color: #e55353">*</span></label>
          <a-select
              placeholder="Тип скидки"
              style="width: 100%"
              v-model="item.type"
              optionLabelProp="label"
              optionFilterProp="label"
              :default-value="'residential'"
              :allowClear="true"
              :class="{'is-invalid': validateErrors.type}"
            >
            <a-select-option v-for="type in types" :key="type.value" :value="type.value" :label="type.title">
              {{type.title}}
            </a-select-option>
          </a-select>
          <div class="invalid-feedback" v-for="value in validateErrors.type"> {{value}} </div>
        </div>
        <div class="mb-3 ml-1 w-50">
          <label for="">Скидка (значение) <span style="color: #e55353">*</span></label>
          <template>
            <a-input placeholder="Скидка (значение)" v-model="item.value" :class="{'is-invalid': validateErrors.value}"
            />
            <div class="invalid-feedback" v-for="value in validateErrors.value"> {{value}} </div>
          </template>
        </div>
      </div>
      <hr>
      <div class="d-flex">
        <div class="mb-3 w-50">
          <label for="">Тип услуги <span style="color: #e55353">*</span></label>
          <a-select
              placeholder="Тип услуги"
              style="width: 100%"
              v-model="item.type_of_service"
              optionLabelProp="label"
              optionFilterProp="label"
              :default-value="'residential'"
              :allowClear="true"
              :class="{'is-invalid': validateErrors.type_of_service}"
              @change="filterTariffs"
            >
            <a-select-option v-for="type in typeOfServices" :key="type.value" :value="type.value" :label="type.title">
              {{type.title}}
            </a-select-option>
          </a-select>
          <div class="invalid-feedback" v-for="value in validateErrors.type_of_service"> {{value}} </div>
        </div>
        <div class="ml-5">
          <div class="form-group">
            <label for="exampleInputEmail">Статус промокода  <span :style="{color: status == 'Активный' ? '#2EB85C' : 'red'}" >({{status}})</span> </label>
            <a-form-item>
              <div v-if="item.status=='active'">
                <div>
                  <a-switch  default-checked @change="changeSwitchItem" />
                </div>
              </div>
              <div v-else>
                <div>
                  <a-switch  v-decorator="['switch', { valuePropName: 'active' }]" @change="changeSwitchItem" />
                </div>
              </div>
            </a-form-item>
            <div class="invalid-feedback" v-for="value in validateErrors.status"> {{value}} </div>
          </div>
        </div>
      </div>

      <div class="d-flex">
        <div class="mb-3 mr-1">
          <label for="">Дата и время от</label>
          <a-date-picker :defaultValue="item.date_from" style="width: 100%" @change="changeDateFromInEdit" placeholder="Выберите дату и время" show-time format="DD-MM-YYYY HH:mm:ss"></a-date-picker>
        </div>
        <div class="mb-3 ml-1">
          <label for="">Дата и время до</label>
          <a-date-picker :defaultValue="item.date_to" style="width: 100%" @change="changeDateToInEdit" placeholder="Выберите дату и время" show-time format="DD-MM-YYYY HH:mm:ss"></a-date-picker>
        </div>
      </div>

      <div class="d-flex">
        <div class="mb-3 mr-1">
          <label for="">Количество <span style="color: #e55353">*</span></label>
          <template>
            <a-input placeholder="Количество" v-model="item.count" :class="{'is-invalid': validateErrors.count}"
            />
            <div class="invalid-feedback" v-for="value in validateErrors.count"> {{value}} </div>
          </template>
        </div>
        <div class="mb-3 ml-1 mr-1">
          <label for="">Использовано </label>
          <template>
            <a-input disabled placeholder="Использовано" v-model="item.use" :class="{'is-invalid': validateErrors.use}"
            />
            <div class="invalid-feedback" v-for="value in validateErrors.use"> {{value}} </div>
          </template>
        </div>
        <div class="mb-3 mr-1">
          <label for="">Осталось </label>
          <template>
            <a-input disabled placeholder="Осталось" v-model="item.remainder" :class="{'is-invalid': validateErrors.remainder}"
            />
            <div class="invalid-feedback" v-for="value in validateErrors.remainder"> {{value}} </div>
          </template>
        </div>
      </div>
      <hr>
      <div class="">
        <label for="">Тарифы</label>
        <a-select
            mode="multiple"
            placeholder="Выберите тарифа"
            v-model="item.tariffs_id"
            style="width: 100%"
            optionLabelProp="label"
            optionFilterProp="label"
           :class="{'is-invalid': validateErrors.tariffs_id}"
          >
          <a-select-option v-for="tariffs in tariffs" :key="tariffs.id" :value="tariffs.id" :label="tariffs.title">
            {{tariffs.title}}
          </a-select-option>
        </a-select>
        <div class="invalid-feedback" v-for="value in validateErrors.tariffs_id"> {{value}} </div>
      </div>
      <hr>

      <div class="d-flex mt-3">
        <button type="submit" class="ml-auto btn btn-primary" @click="updateItem">Сохранить</button>
      </div>
    </a-modal>

    <!-- Show Modal -->
    <a-modal v-model="visibleShowModal" :title="item.title"  footer="" class="address-create">
      <table class="table table-striped">
        <tbody>
          <tr>
            <td>Кто создал</td>
            <td> <span v-if="item.creator" style="color: #58A4FD; cursor: pointer">{{item.creator.name}}</span> <span v-else>--</span> </td>
          </tr>
          <tr>
            <td>Клиент</td>
            <td> <span v-if="item.client" style="color: #5355D3; cursor: pointer">{{item.client.name}}</span> <span v-else>--</span> </td>
          </tr>
          <tr>
            <td>Код</td>
            <td> {{item.code}} </td>
          </tr>
          <tr>
            <td>Описание</td>
            <td> {{item.title}} </td>
          </tr>
          <tr>
            <td>Заголовок</td>
            <td> {{item.description}} </td>
          </tr>
          <tr>
            <td>Статус</td>
            <td> <span class="btn btn-success" v-if="item.status == 'active'">Активный</span> <span class="btn btn-danger" v-else>Неактивный</span> </td>
          </tr>
          <tr>
            <td>Минимальная цена</td>
            <td> {{item.min_price}} - UZS</td>
          </tr>
          <tr>
            <td>Максимальная цена</td>
            <td> {{item.max_price}} - UZS</td>
          </tr>
          <tr>
            <td>Тип услуги</td>
            <td> <span v-if="item.type_of_service == 'international'" class="btn btn-success">Международный</span> <span v-else-if="item.type_of_service == 'local'" class="btn btn-info">Местный</span> <span v-else class="btn btn-primary">Оба</span> </td>
          </tr>
          <tr>
            <td>Скидка</td>
            <td> {{item.value}} - UZS</td>
          </tr>
          <tr>
            <td>Действительно от</td>
            <td> {{item.date_from}} </td>
          </tr>
          <tr>
            <td>Действительно до</td>
            <td> {{item.date_to}} </td>
          </tr>

          <tr>
            <td>Всего количество</td>
            <td> {{item.count}} </td>
          </tr>
          <tr>
            <td>Использовано</td>
            <td> {{item.use ? item.use : 0}} </td>
          </tr>
          <tr>
            <td>Осталось</td>
            <td> {{item.remainder}} </td>
          </tr>
          <tr>
            <td>Тарифы</td>
            <td>
                  <div v-if="item.tariffs.length">
                    <span class="btn btn-primary" v-for="tariff in item.tariffs">{{tariff.title}}</span>
                  </div>
                  <div v-else>
                    -- --
                  </div>
            </td>
          </tr>
        </tbody>
      </table>

    </a-modal>
    <!-- Delete modal -->
    <a-modal v-model="visibleDeleteModal" title="Подтверждение на удаление" ok-text="Удалить" cancel-text="Отменить" @ok="destroyItem">
      <h6 style="color: red">Точно хотите безвозвратно удалить ?</h6>
    </a-modal>

  </div>
</template>

<script>
import axios from 'axios'
import moment, { Moment } from 'moment';
import { defineComponent, ref } from 'vue';
const data = '';
const dateFormat = 'DD-MM-YYYY HH:mm:ss';

export default {
  name: "Clients",
  data() {
    return {
      index: null,
      data,
      item: {
        client_id: '',
        code: '',
        title: '',
        description: '',
        min_price: '',
        max_price: '',
        type: '',
        value: '',
        status: 'active',
        type_of_service: '',
        date_from: ref<Moment>(moment('12-12-2000 00:00:00', dateFormat)),
        date_to: ref<Moment>(moment('12-12-2100 23:59:59', dateFormat)),
        count: '',
        tariffs_id: [],
        tariffs: [],

      },
      newItem: {
        client_id: '',
        code: '',
        title: '',
        description: '',
        min_price: '',
        max_price: '',
        type: '',
        value: '',
        status: 'active',
        type_of_service: '',
        date_from: ref<Moment>(moment('12-12-2000 00:00:00', dateFormat)),
        date_to: ref<Moment>(moment('12-12-2100 23:59:59', dateFormat)),
        count: '',
        tariffs_id: []
      },
      oldItem: {
        client_id: '',
        code: '',
        title: '',
        description: '',
        min_price: '',
        max_price: '',
        type: '',
        value: '',
        status: 'active',
        type_of_service: '',
        date_from: ref<Moment>(moment('12-12-2000 00:00:00', dateFormat)),
        date_to: ref<Moment>(moment('12-12-2100 23:59:59', dateFormat)),
        count: '',
        tariffs_id: []
      },
      dataFilter: {
        clients: [],
        date_from: ref<Moment>(moment('12-12-2000 00:00:00', dateFormat)),
        date_to: ref<Moment>(moment('12-12-2100 23:59:59', dateFormat)),
        code: '',
        page: 1
      },
      validateErrors: [],

      types: [{title: "Бесплатный заказ", value: "free_order"},{title: "Скидка на процентах", value: "percentage_discount"},{title: "Фиксированная цена", value: "absolute_discount"}],
      statuses: [{title: "Активный", value: "avtive"},{title: "Не активный", value: "inavtive"}],
      typeOfServices: [{title: "Международный", value: "international"},{title: "Местный", value: "local"},{title: "Оба", value: "both"}],
      clients: [],
      tariffs: [],
      allTariffs: [],
      status: "Активный",

      index: '',
      currentPage: 1,
      totalResults: 0,
      scrolled: false,
      old_height: 0,
      last_page: 1,

      load: true,
      visibleShowModal: false,
      visibleEditModal: false,
      visibleCreateModal: false,
      visibleDeleteModal: false,

      visibleMap: false,
      center: {
          lat: 41.31087153905742,
          lng: 69.27891510052102
      },
      location: {
        lat: '',
        lng: ''
      },

      visibleMapForEdit: false,
      centerForEdit: {
          lat: 41.31087153905742,
          lng: 69.27891510052102
      },
      locationForEdit: {
        lat: '',
        lng: ''
      },

    }
  },
  mounted(){

    this.getPromoCodes();
    this.getAllTariffs();

    this.getClients();

    window.onscroll = () => {
      let height = document.getElementById('addressesList').clientHeight
      if ((window.innerHeight + document.documentElement.scrollTop) >= height) {
        if(this.old_height!=height){
          this.old_height = height;
          let limit = this.currentPage + 1;
          if(limit <= this.last_page){
            console.log(limit);
            console.log(this.last_page);
            this.dataFilter.page = limit;
            this.addPromocodes();
          }
        }
      }
    };

  },
  methods: {
    changeSwitchItem(value){
      this.newItem.status = value ? 'active' : 'inactive';
      this.item.status = value ? 'active' : 'inactive';
      this.status = value ? 'Активный' : 'Неактивный';
      console.log(this.user);
    },
    changeDateTo(date, dateString){
      this.dataFilter.date_to = dateString;
      this.getPromoCodes();
    },
    changeDateFrom(date, dateString){
      this.dataFilter.date_from = dateString;
      this.getPromoCodes();
    },
    changeDateToInCreate(date, dateString){
      this.newItem.date_to = dateString;
    },
    changeDateFromInCreate(date, dateString){
      this.newItem.date_from = dateString;
    },
    changeDateToInEdit(date, dateString){
      this.item.date_to = dateString;
      console.log(dateString);
    },
    changeDateFromInEdit(date, dateString){
      this.item.date_from = dateString;
      console.log(dateString);
    },
    createdAt(date){
      let d = new Date(date).toLocaleDateString();
      return moment(date).format('DD-MM-YYYY HH:mm:ss');
    },

    addPromocodes(){
      this.load = true;
      axios.get('/promocodes', {params: this.dataFilter }).then((response) => {
        console.log(response.data);
        if(response.status == 200){
          this.data = this.data.concat(response.data.data);
          this.totalResults = response.data.total;
          this.last_page = response.data.last_page;
          this.currentPage = response.data.current_page;
        }else if(response.status == 204){
          this.$message.error(
            "Нет соответствующих адресов",
            3
          )
        }
      }).catch((error) => {

      }).finally(()=>{
        this.load = false;
      });
    },
    getPromoCodes(){
      this.dataFilter.page = 1;
      this.old_height = 0;
      axios.get('/promocodes', {params: this.dataFilter }).then((response) => {
        if(response.status == 200){
          this.data = response.data.data;
          this.totalResults = response.data.total;
          this.last_page = response.data.last_page;
          this.currentPage = response.data.current_page;
          console.log(response.data);
        }else if(response.status == 204){
          this.data = [];
          this.totalResults = 0;
          this.last_page = 0;
          this.currentPage = 0;
          this.$message.error(
            "Нет промокодов",
            3
          )
        }
      }).catch((error) => {

      }).finally(()=>{
        this.load = false;
      });
    },

    createItem(){
      this.visibleCreateModal = true;
    },
    editItem(value){
      console.log(value);
      this.visibleEditModal = true;
      this.item = value;
      // let date_from = new Date(value.date_from).toLocaleDateString();
      // let time_from = new Date(value.date_from).toLocaleTimeString();
      // let date_to = new Date(value.date_to).toLocaleDateString();
      // let time_to = new Date(value.date_to).toLocaleTimeString();
      // this.item.date_from = moment(date_from + ' ' + time_from).format('DD-MM-YYYY HH:mm:ss');
      // this.item.date_to = moment(date_to + ' ' + time_to).format('DD-MM-YYYY HH:mm:ss');
      this.validateErrors = [];
      if(value.status === 'active'){
        this.status = 'Активный';
      }else{
        this.status = 'Неактивный';
      }
      this.$forceUpdate();
    },
    showItem(value){
      console.log(value);
      this.visibleShowModal = true;
      this.item = value;
      // let date_from = new Date(value.date_from).toLocaleDateString();
      // let time_from = new Date(value.date_from).toLocaleTimeString();
      // let date_to = new Date(value.date_to).toLocaleDateString();
      // let time_to = new Date(value.date_to).toLocaleTimeString();
      // this.item.date_from = moment(date_from + ' ' + time_from).format('DD-MM-YYYY HH:mm:ss');
      // this.item.date_to = moment(date_to + ' ' + time_to).format('DD-MM-YYYY HH:mm:ss');
      if(value.status === 'active'){
        this.status = 'Активный';
      }else{
        this.status = 'Неактивный';
      }
      this.$forceUpdate();
    },
    deleteItem(index, value){
      this.item = value;
      this.index = index;
      this.visibleDeleteModal = true;
    },

    storeItem(){
        this.load = true;
        axios.post('/promocodes', this.newItem).then((response) => {
          if(response.status == 201){ console.log(response.data);
            this.data = this.data.concat(response.data);
            this.totalResults++;
            this.newItem = this.oldItem;
            this.validateErrors = [];
            this.visibleCreateModal = false;
            this.$message.success(
              "Успешно добавлено",
              3
            );

          }
        }).catch((error) => {
          console.log(error.response.data);
          if(error.response.status == 422){
            this.$message.error(
              error.response.data.message,
              3
            );
            this.validateErrors = error.response.data.errors
          }else if(error.response.status == 404){
            this.$message.error(
              error.response.data.error,
              3
            );
          }
        }).finally(() => {
          this.load = false;
        })
    },
    updateItem(){
      this.load = true;
      axios.put('/promocodes/'+this.item.id, this.item).then((response) => {
        if(response.status == 200){
          this.$message.success(
            "Успешно изменено",
            3
          );
          this.validateErrors = [];
          this.visibleEditModal = false;
        }
      }).catch((error) => {
        if(error.response.status == 422){
          this.$message.error(
            error.response.data.message,
            3
          );
          this.validateErrors = error.response.data.errors;
        }else if(error.response.status == 404){
          this.$message.error(
            error.response.data.error,
            3
          )
        }else if(error.response.status == 500){
          this.$message.error(
            "Ошибка сервера",
            3
          );
        }
      }).finally(() => {
        this.load = false;
      });
    },
    destroyItem(){
      this.load = true;
      axios.delete('/promocodes/'+this.item.id).then((response) => {
        if(response.status == 200){
          this.$message.success(
            response.data.success,
            3
          );
          this.data.splice(this.index, 1)
          this.totalResults--;
          this.visibleDeleteModal = false;
        }
      }).catch((error) => {
        if(error.response.status == 404){
          this.$message.error(
            error.response.data.error,
            3
          );
        }else if(error.response.status == 400){
          this.$message.error(
            error.response.data.error,
            3
          );
        }else if(error.response.status == 500){
          this.$message.error(
            "Ошибка сервера",
            3
          );
        }
      }).finally(() => {
        this.load = false;
      });
    },


    getClients(){
      axios.get('/employee/clients').then(response => {
        if(response.status == 200){
          this.clients = response.data
        }else{
          this.$message.error(
            'Клиенты не найдено',
            3
          );
        }
      }).catch(error => {
        if(error.response.status == 500){
          this.$message.error(
            'Ошибка сервера',
            3
          );
        }else{
          this.$message.error(
            'Клиенты не найдено',
            3
          );
        }
      }).finally(() => {
        this.load = false;
      });
    },
    getAllTariffs(){
      axios.get('/employee/tariffs/all').then((response) => {
        if(response.status == 200){
          this.tariffs = response.data;
          this.allTariffs = response.data;
        }else if(response.status == 204){
          this.$message.error(
            'Тарифы не найдено',
            3
          );
        }
      }).catch((error) => {
        if(error.response.status == 500){
          this.$message.error(
            'Ошибка сервера',
            3
          );
        }
      })
    },

    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
      );
    },

    applyFilter(){
      this.load = true;
      this.getPromoCodes();
    },

    filterTariffs(){
      this.tariffs = this.allTariffs;
      if(this.newItem.client_id){
        let client = this.clients.find((el) => el.id===this.newItem.client_id)
        this.tariffs = this.tariffs.filter((el) => el.client_type === client.type || el.client_type === null);
      }
      if(this.newItem.type_of_service){
        if(this.newItem.type_of_service != 'both'){
          this.tariffs = this.tariffs.filter((el) => el.type === this.newItem.type_of_service);
        }
      }

      let tariffsOnFilter = [];
      for(let i=0; i<this.tariffs.length; i++){
        if(this.tariffs[i].rules.length){
          if(this.parseTariff(this.tariffs[i].rules[0], this.tariffs[i].rules[0].bool_and_or)){
            tariffsOnFilter.push(this.tariffs[i]);
          }
        }else{
          tariffsOnFilter.push(this.tariffs[i]);
        }
      }
      this.tariffs = tariffsOnFilter;
    },
    parseTariff(tariff, boolAndOr){
      if(tariff.conditions.length){
        let arr = [];
        for(let i=0; i<tariff.conditions.length; i++){
          if(tariff.conditions[i].client && this.newItem.client_id){
            if(tariff.conditions[i].value == 'equally'){
              if(tariff.conditions[i].client == this.newItem.client_id){
                arr[i] = true;
              }else{
                arr[i] = false;
              }
            }else if(tariff.conditions[i].value == 'unequally'){
              if(tariff.conditions[i].client != this.newItem.client_id){
                arr[i] = true;
              }else{
                arr[i] = false;
              }
            }
          }else{
            arr[i] = true;
          }
        }
        if(!arr.length){arr[0]=true;}
        let boolValue = arr[0];
        if(boolAndOr==='or'){
          if(arr.length>1){
            for(let i=1; i<arr.length; i++){
              boolValue += arr[i];
            }
          }
        }else if(boolAndOr==='and'){
          if(arr.length>1){
            for(let i=1; i<arr.length; i++){
              boolValue *= arr[i];
            }
          }
        }
        return boolValue;
      }else{
        if(tariff.rules.length){
          let law = [];
          for(let i=0; i<tariff.rules.length; i++){
             law[i] = this.parseTariff(tariff.rules[i], tariff.rules[i].bool_and_or);
          }
          if(!law.length){law[0]=true}
          let boolValue = law[0];
          if(boolAndOr==='or'){
            if(law.length>1){
              for(let i=1; i<law.length; i++){
                boolValue += law[i];
              }
            }
          }else if(boolAndOr==='and'){
            if(law.length>1){
              for(let i=1; i<law.length; i++){
                boolValue *= law[i];
              }
            }
          }
          return boolValue;
        }
      }
    },


  },
  computed: {
    me () {
      return JSON.parse(localStorage.me)
    }
  },
}
</script>

<style>
  .ant-modal-mask{
    z-index: 2000 !important;
  }
  .ant-modal-wrap{
    z-index: 2000 !important;
  }
  .ant-message{
    z-index: 2010 !important
  }
  .ant-message-notice{
    z-index: 2020 !important;
  }
  .ant-message-notice-content{
    z-index: 2020 !important;
  }
  .ant-select-dropdown{
    z-index: 2000 !important;
  }
  .ant-calendar-picker-container{
    z-index: 2030 !important;
  }
  .address-create .ant-modal{
    width: 600px !important;
    height: 100%;
    top: 10px;
  }
  .map__modal .ant-modal{
    width: 1000px !important;
    height: 500px !important;
    top: 10px;
  }

  .my-img-circle{
    width: 80px;
    height: 80px;
    border-radius: 50%;
    overflow: hidden;
    border: 3px solid #e9e9e9;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .my-img-circle img{
    width: 75px;
  }




  .dropZone {
    width: 100%;
    height: 200px;
    position: relative;
    border: 2px dashed #eee;
  }

  .dropZone:hover {
    border: 2px dotted #2e94c4;
  }

  .dropZone:hover .dropZone-title {
    color: #1975A0;
  }

  .dropZone-info {
    color: #A8A8A8;
    position: absolute;
    top: 50%;
    width: 100%;
    transform: translate(0, -50%);
    text-align: center;
  }

  .dropZone-title {
    color: #787878;
  }

  .dropZone input {
    position: absolute;
    cursor: pointer;
    top: 0px;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
  }

  .dropZone-upload-limit-info {
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
  }

  .dropZone-over {
    background: #5C5C5C;
    opacity: 0.8;
  }

  .dropZone-uploaded {
    width: 100%;
    height: 200px;
    position: relative;
    border: 2px dashed #eee;
  }

  .dropZone-uploaded-info {
    display: flex;
    flex-direction: column;
    align-items: center;
    color: #A8A8A8;
    position: absolute;
    top: 50%;
    width: 100%;
    transform: translate(0, -50%);
    text-align: center;
  }

  .removeFile {
    width: 200px;
  }
</style>
